import "./App.css";
import WebFont from "webfontloader";
import { useEffect } from "react";
import logo from "./logo.png";

function App() {
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Bebas-Regular"],
        urls: ["./Bebas-Regular.ttf"],
      },
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          alt="Spark! Logo"
          style={{ width: "8vw", paddingRight: "1%", paddingLeft: "3%" }}
        />
        <p style={{ marginLeft: "auto", marginRight: "auto" }}>
          What's The Next Stop For Spark! Graduates?
        </p>
      </header>
      <div
        style={{ width: "100vw", display: "flex", height: "100%" }}
        dangerouslySetInnerHTML={{
          __html:
            "<iframe src='https://www.geosheets.com/map/s:Gb7G13bk/Spark--Graduates-in-the-World/embed' style='border:none;width:100vw;height:100%;display:flex'></iframe>",
        }}
      />
    </div>
  );
}

export default App;
